<template>
  <div>
    <div class="d-flex justify-space-between">
      <!-- left -->
      <div class="d-flex" style="gap: 20px">
        <!-- search -->
        <v-text-field
          v-model="search"
          clearable
          outlined
          dense
          placeholder="Search name, email, or etc."
          prepend-inner-icon="mdi-magnify"
          color="primary"
        ></v-text-field>

        <!-- filter by date range -->
        <v-menu
          v-model="dateMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          max-width="290px"
          min-width="auto"
          offset-y
          nudge-top="20"
        >
          <template v-slot:activator="{ attrs, on }">
            <v-text-field
              :value="dateRangeText"
              placeholder="Filter by Date Range"
              v-bind="attrs"
              outlined
              dense
              append-icon="mdi-calendar"
              v-on="on"
              clearable
              readonly
              @click:clear="dateRange = []"
            ></v-text-field>
          </template>
          <v-date-picker
            color="primary"
            v-model="dateRange"
            no-title
            range
            scrollable
            :max="maxDate"
            @input="onDateSelected"
          >
          </v-date-picker>
        </v-menu>

        <!-- filter by executive -->
        <v-select
          :menu-props="{ bottom: true, offsetY: true }"
          append-icon="mdi-chevron-down"
          v-model="sales"
          :items="executive_list"
          :loading="executive_list_loading"
          item-text="name"
          item-value="id"
          clearable
          outlined
          dense
          placeholder="Filter by Sales"
          v-if="[$keys.SALES_HEAD, $keys.ACCOUNTS].includes(user.user_type)"
        >
        </v-select>
      </div>

      <!-- right -->
      <div>
        <!-- add payment btn -->
        <v-btn
          class="text-capitalize px-7"
          color="primary"
          height="40px"
          outlined
          @click="
            toggleAddUpdateDialog({
              show: true,
              id: null,
              caseId: null,
              type: 'add',
            })
          "
        >
          <v-icon size="16" left>mdi-plus</v-icon>add payment
        </v-btn>
      </div>
    </div>

    <v-sheet elevation="3" class="rounded-b-0">
      <v-tabs
        height="125px"
        next-icon="mdi-chevron-right-circle"
        prev-icon="mdi-chevron-left-circle"
        v-model="activeTab"
        show-arrows
      >
        <v-tab v-for="(item, id) in tabs" :key="id" class="tab-width">
          <div>
            <v-icon
              size="20"
              style="margin-top: -25px; font-size: 45px"
              :color="item.color"
              >mdi-circle-small</v-icon
            >

            <div class="tab-total" style="margin-top: -10px">
              {{ item.value }}
            </div>
            <div class="tab-name">
              {{ item.label }}
            </div>
          </div>
        </v-tab>
      </v-tabs>
    </v-sheet>

    <v-divider></v-divider>

    <paymentTable ref="dataTable" @reloadData="getListCount" />
    <AddUpdatePayment @submitted="reloadData" />
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

import paymentTable from "./paymentTable.vue";
import AddUpdatePayment from "@/components/CaseManagement/Payment/AddUpdateDialog";

export default {
  components: { paymentTable, AddUpdatePayment },

  data() {
    return {
      dateMenu: false,

      executive_list: [],
      executive_list_loading: false,

      tabs: [
        {
          label: `Pending`,
          key: "pending",
          value: 0,
          color: "#1149B7",
        },
        {
          label: `Successful`,
          key: "successful",
          value: 0,
          color: "#3DA922",
        },
        {
          label: `Cancelled`,
          key: "cancelled",
          value: 0,
          color: "red",
        },
      ],
    };
  },

  computed: {
    ...mapGetters({
      user: "getUser",
      getFilters: "payments/filters",
      getSearch: "payments/search",
      getActiveTab: "payments/activeTab",
    }),

    activeTab: {
      get() {
        return this.getActiveTab.index;
      },
      set(value) {
        this.setActiveTab({ index: value, key: this.tabs[value].key });
      },
    },

    maxDate() {
      const today = this.$moment();
      return today.format("YYYY-MM-DD");
    },

    dateRangeText() {
      const _filter_by_date = this.dateRange.map((item) =>
        this.$moment(item).format("DD-MM-YYYY")
      );
      return _filter_by_date.join(" ~ ");
    },

    search: {
      get() {
        return this.getSearch;
      },
      set(value) {
        this.setSearch(value);
      },
    },

    dateRange: {
      get() {
        return this.getFilters.date;
      },
      set(value) {
        this.setFilters({ date: value });
      },
    },

    sales: {
      get() {
        return this.getFilters.sales;
      },
      set(value) {
        this.setFilters({ sales: value });
      },
    },
  },

  mounted() {
    this.getListCount();
    this.getExecutiveList();
    this.$root.$on("onNavClicked", this.navClickHandler);
  },

  beforeDestroy() {
    this.$root.$off("onNavClicked", this.navClickHandler);
  },

  methods: {
    ...mapActions({
      setSearch: "payments/setSearch",
      setFilters: "payments/setFilters",
      setActiveTab: "payments/setActiveTab",
      toggleAddUpdateDialog: "payments/toggleAddUpdateDialog",
    }),

    navClickHandler(navKey) {
      if (navKey === "payment_management") {
        this.reloadData();
        this.$refs.dataTable.updateRoute();
      }
    },

    reloadData() {
      this.getListCount();
      this.$refs.dataTable.getPaymentList();
    },

    onDateSelected() {
      if (this.dateRange.length === 2) this.dateMenu = false;
    },

    getExecutiveList() {
      this.executive_list_loading = true;

      const params = {
        apply_pagination: false,
        list_type: "assign_sales",
      };

      const onSuccess = (res) => {
        this.executive_list = res.data.data;
      };

      const onFinally = () => {
        this.executive_list_loading = false;
      };

      return this.$request(this.$keys.GET, this.$urls.team.list, {
        params,
        onSuccess,
        onFinally,
      });
    },

    getListCount() {
      const onSuccess = (res) => {
        this.tabs.forEach((tab) => {
          tab.value = res.data.data[tab.key];
        });
        this.$forceCompute("tabs");
      };

      const params = {
        search: this.search,
        executive_id: this.sales,
      };

      if (this.dateRange) {
        if (this.dateRange.length === 2) {
          params["start_date"] = this.dateRange[0];
          params["end_date"] = this.dateRange[1];
        } else {
          params["date"] = this.dateRange[0];
        }
      }

      return this.$request(this.$keys.GET, this.$urls.case.payment.count, {
        params,
        onSuccess,
      });
    },
  },
};
</script>
<style scoped>
.tab-total {
  font-weight: 700;
  font-size: 30px;
  line-height: 36px;
}
.tab-name {
  word-wrap: break-word;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
}
.active-class {
  background: #f2ffff;
}
</style>
